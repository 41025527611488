import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const TimerWrapper = styled.div`
  display: ${props => (props.isMobile ? 'grid' : 'flex')};
  grid-template-columns: ${props => (props.isMobile ? '1fr 1fr' : '')};
  justify-items: ${props => (props.isMobile ? 'center' : '')};
`;
const Timer = styled.div`
  width: 8rem;
  margin-top: ${props => (props.isMobile ? '7rem' : '5rem')};
  margin-right: ${props => (props.isMobile ? 'grid' : '5rem')};
  text-align: center;
`;
const TimerNumber = styled.div`
  font-size: 6.4rem;
  color: #8454fd;
  font-weight: bold;
`;
const TimerText = styled.div`
  font-size: 1.4rem;
  color: #aaaaaa;
  text-transform: uppercase;
`;

const Counter = () => {
  const calculateTimeLeft = () => {
    const difference = +new Date(`2023-7-1`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: ('0' + Math.floor(difference / (1000 * 60 * 60 * 24))).slice(-2),
        hours: ('0' + Math.floor((difference / (1000 * 60 * 60)) % 24)).slice(-2),
        minutes: ('0' + Math.floor((difference / 1000 / 60) % 60)).slice(-2),
        seconds: ('0' + Math.floor((difference / 1000) % 60)).slice(-2),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

  React.useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  const timerComponents = Object.keys(timeLeft).map(interval => {
    if (!timeLeft[interval]) {
      return;
    }

    return (
      <Timer key={interval} isMobile={isMobile}>
        <TimerNumber>{timeLeft[interval]}</TimerNumber>
        <TimerText>{interval}</TimerText>
      </Timer>
    );
  });

  return <TimerWrapper isMobile={isMobile}>{timerComponents.length ? timerComponents : <span>Time's up!</span>}</TimerWrapper>;
};
export default Counter;
