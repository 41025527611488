import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

import logo from '../../assets/images/logo.svg'

import LinksMedia from '../LinksMedia/LinksMedia'
import ServicesReel from '../ServicesReel/ServicesReel'

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isMobile ? 'center' : 'space-between')};
`
const Logo = styled.img`
  max-width: 31.5rem;
  height: auto;
`

const Header = () => {
  return (
    <HeaderWrapper isMobile={isMobile}>
      <div>
        <Logo src={logo} alt="Kiddo's Vision Logo" />
      </div>
      {!isMobile && <ServicesReel />}
      {!isMobile && <LinksMedia />}
    </HeaderWrapper>
  )
}

export default Header
