import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

import dude from '../assets/images/Dude-in-lazy-bag.png'
import dudeBack from '../assets/images/dude-back.svg'
import lines from '../assets/images/lines.svg'
import circle from '../assets/images/circle.svg'

import Header from '../components/Header/Header'
import Counter from '../components/Counter/Counter'
import LinksMedia from '../components/LinksMedia/LinksMedia'
import ServicesReel from '../components/ServicesReel/ServicesReel'

const AppWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: ${(props) => (props.isMobile ? '4rem' : '8.5rem 14rem')};
  height: 100vh;
  max-height: ${(props) => (props.isMobile ? '100%' : '100vh')};
  box-sizing: border-box;
  overflow: ${(props) => (props.isMobile ? 'initial' : 'hidden')}; ;
`
const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.isMobile ? '1fr' : '1fr 1fr')};
`
const SectionLeft = styled.div`
  margin-top: 10rem;
  text-align: ${(props) => (props.isMobile ? 'center' : 'left')};
`
const BigText = styled.h1`
  font-size: ${(props) => (props.isMobile ? '10rem' : '12rem')};
  margin: 0;
  line-height: 0.8;
  color: white;
  text-shadow: -2px -2px 0 #dedede, 2px -2px 0 #dedede, -2px 2px 0 #dedede,
    2px 2px 0 #dedede;
`
const SubtitleText = styled.h1`
  font-size: 4rem;
  margin: 0;
  color: #ff8532;
  font-weight: 600;
`

const SectionRight = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`
const ImgDude = styled.img`
  padding-top: 10rem;
  width: 100%;
  height: auto;
`
const ImgDudeBack = styled.img`
  position: absolute;
  z-index: -1;
  max-width: 40%;
  top: 0;
`
const ImgCircle = styled.img`
  position: absolute;
  right: -20rem;
  width: 32rem;
  height: auto;
  z-index: -1;
  top: ${(props) => (props.isMobile ? '65%' : '50%')};
  transform: ${(props) => (props.isMobile ? 'none' : 'translateY(-50%)')};
`
const ImgLines = styled.img`
  position: absolute;
  bottom: -12rem;
  left: 14rem;
  z-index: -1;
`

const UnderConstruction = () => {
  return (
    <AppWrapper isMobile={isMobile}>
      {!isMobile && <ImgLines src={lines} alt="" />}
      <ImgCircle isMobile={isMobile} src={circle} alt="" />
      <Header />
      <SectionWrapper isMobile={isMobile}>
        <SectionLeft isMobile={isMobile}>
          <BigText isMobile={isMobile}>NEW</BigText>
          <BigText isMobile={isMobile}>WEBSITE</BigText>
          <SubtitleText>UNDER CONSTRUCTION</SubtitleText>
          <Counter />
        </SectionLeft>
        <SectionRight>
          <ImgDude src={dude} alt="" />
          {!isMobile && <ImgDudeBack src={dudeBack} alt="" />}
        </SectionRight>
      </SectionWrapper>
      {isMobile && <ServicesReel />}
      {isMobile && <LinksMedia />}
    </AppWrapper>
  )
}

export default UnderConstruction
