import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

import telegram from '../../assets/images/telegram.svg'
import linkedin from '../../assets/images/linkedin.svg'

const ImageLink = styled.a`
  margin-left: 2rem;
  border: none;
  display: inline-block;

  &:hover {
    border: none;
  }
`
const LinkWrapper = styled.div`
  padding-bottom: ${(props) => (props.isMobile ? '8rem' : '')};
  text-align: ${(props) => (props.isMobile ? 'center' : '')};
`
const LinksMedia = () => {
  return (
    <LinkWrapper isMobile={isMobile}>
      <ImageLink href="https://t.me/velikis" target="_blank">
        <img src={telegram} alt="Telegram link" />
      </ImageLink>
      <ImageLink
        href="https://www.linkedin.com/in/velimir-kisic-55646169/"
        target="_blank"
      >
        <img src={linkedin} alt="Telegram link" />
      </ImageLink>
    </LinkWrapper>
  )
}

export default LinksMedia
