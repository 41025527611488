import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import pdf from '../../assets/pdf/Kiddos_vision_offer_document.pdf';

const Links = styled.div`
  margin: ${props => (props.isMobile ? '8rem 0' : '')};
  display: flex;
  justify-content: ${props => (props.isMobile ? 'center' : 'start')};
  flex-grow: 1;
  padding: 0 10rem;
`;
const Link = styled.a`
  margin-left: 4rem;
  color: #5c5c5c;
  font-size: ${props => (props.isMobile ? '3rem' : '1.6rem')};
  border-bottom: 1px solid transparent;
  &:hover {
    border-bottom: 1px solid #5c5c5c;
  }
`;

const ServicesReel = () => {
  return (
    <Links isMobile={isMobile}>
      <Link href={pdf} target="_blank" isMobile={isMobile}>
        SERVICES
      </Link>
      <Link href="https://vimeo.com/795519835" target="_blank" isMobile={isMobile}>
        REEL
      </Link>
    </Links>
  );
};

export default ServicesReel;
